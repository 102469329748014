"use strict";

angular.module("informaApp")
    .service("NavigatorService", ["ConstantsSvc", "$location", function (ConstantsSvc, $location) {
        const openPage = (s) => {
            $location.path(s);
        };

        return {
            openTable: function () {
                openPage(ConstantsSvc.urls.table);
            },
            openPredictive: function () {
                openPage(ConstantsSvc.urls.predictive);
            },
            openChart: function () {
                openPage(ConstantsSvc.urls.chart);
            },
            openCompanyProfile: function () {
                openPage(ConstantsSvc.urls.companyProfile);
            },
            openComparison: function () {
                openPage(ConstantsSvc.urls.comparison);
            },
            openGanttChart: function () {
                openPage(ConstantsSvc.urls.ganttChart);
            },
            openSummaryView() {
                openPage(ConstantsSvc.urls.summaryView);
            },
            openInitialPage: function () {
                openPage('/');
            },
            OpenCustomPage: function (url) {
                openPage(url);
            },
            openFeatureCard(featureId) {
                openPage(`${ConstantsSvc.urls.newFeatures}/${featureId}`);
            },
            openTrendsView() {
                openPage(`${ConstantsSvc.urls.trendsView}`);
            },
            openLogoutRedirect() {
                openPage(`${ConstantsSvc.urls.logoutRedirect}`);
            },
            openLogout() {
                openPage(`${ConstantsSvc.urls.logout}`);
            }
        }
    }]);
    